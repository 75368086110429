import React from 'react';
import { Container as BaseContainer } from '../components/Container';
import { Grid } from '../components/Grid';
import { H3 } from '@increasecard/typed-components';
import styled, { useTheme } from 'styled-components';
import { CTAButton } from '../components/CTAButton';
import GatsbyImage from 'gatsby-image';
import { MEDIUM, SMALL } from '../breakpoints';
import { SuperHeader } from '../components/Typography';

const Container = styled(BaseContainer)`
  padding-top: 0;
  padding-bottom: 40px;
  position: relative;
  bottom: -100px;
  margin-bottom: 100px;

  @media (min-width: ${SMALL}) and (max-width: ${MEDIUM}) {
    bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  grid-column: span 2 / span 2;
  margin-top: 32px;
  @media (min-width: ${SMALL}) {
    margin-top: 0;
    grid-column: 1 / 5;
  }
`;
const ContentWrapper = styled.div`
  @media (min-width: ${SMALL}) {
    grid-column: span 4 / span 4;
  }
  @media (min-width: ${MEDIUM}) {
    grid-column: 5 / span 3;
  }
`;

export function ApiDocsSection({ variant, title, description, cta, image }) {
  const theme = useTheme();
  return (
    <div
      style={{
        background: `linear-gradient(180deg, ${theme.colorsNew.gray.black} 10%, #19303B 46%, ${theme.colorsNew.product.regular} 136.7%)`,
      }}
    >
      <Container>
        <Grid style={{ alignItems: 'center', gridAutoFlow: 'row dense' }}>
          <ContentWrapper>
            <SuperHeader style={{ marginBottom: '24px' }}>{title}</SuperHeader>
            <H3 weight="normal" style={{ marginBottom: '24px' }}>
              {description}
            </H3>
            <CTAButton {...cta} />
          </ContentWrapper>
          <ImageWrapper className="grid-span-md-4">
            <GatsbyImage
              {...(image.childImageSharp || image.imageSharp.childImageSharp)}
            />
          </ImageWrapper>
        </Grid>
      </Container>
    </div>
  );
}
