import React from 'react';
import styled from 'styled-components';
import { BackgroundBase } from './BackgroundBase';

const Intersect1 = styled.div`
  position: absolute;
  width: 380px;
  height: 380px;
  left: -200px;
  bottom: 60px;

  mix-blend-mode: overlay;

  border: 2px solid #ffffff;
  border-radius: 56px;
  transform: rotate(60deg);

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    left: 68px;
    bottom: -100px;
  }
`;
const Intersect2 = styled.div`
  position: absolute;
  width: 620px;
  height: 620px;
  left: -420px;
  bottom: 100px;

  background: #ffffff;
  mix-blend-mode: overlay;
  opacity: 0.3;
  border-radius: 94px;
  transform: rotate(60deg);

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    left: 0;
    bottom: 100px;
  }
`;
const Intersect3 = styled.div`
  position: absolute;
  width: 63px;
  height: 63px;
  right: -30px;
  top: 90px;
  mix-blend-mode: overlay;
  border: 0.8px solid #ffffff;
  border-radius: 9.49888px;
  transform: rotate(60deg);

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    right: 63px;
  }
`;

export function IndustriesBackground({ baseColor, children }) {
  return (
    <BackgroundBase
      baseBackground={baseColor}
      renderBackground={_ => (
        <>
          <Intersect1 /> <Intersect2 />
          <Intersect3 />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
