import React from 'react';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { RoundedSquare } from './shapes/RoundedSquare';
import { BackgroundBase } from './BackgroundBase';

const OutlineSquare = styled(RoundedSquare).attrs({
  size: { sm: '360px', md: '268px' },
  rotation: '40deg',
})`
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colorsNew.product.dark50};
  right: -120px;
  top: calc(40% - 134px);

  @media (min-width: ${MEDIUM}) {
    left: calc(70% - 134px);
    top: calc(45% - 134px);
  }
`;

const baseBackground = variant => theme =>
  variant === 'enterprise'
    ? theme.colorsNew.gray.black
    : theme.colorsNew.gray.white;

export function PaymentMethodsBackground({ children, variant }) {
  return (
    <BackgroundBase
      baseBackground={baseBackground(variant)}
      variant={variant}
      renderBackground={_ => (
        <>
          <OutlineSquare />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
