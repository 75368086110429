import React from 'react';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { SmallRoundedSquare, BigRoundedSquare } from './shapes/RoundedSquare';
import { BackgroundBase } from './BackgroundBase';

const DarkBigSquare = styled(BigRoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.gray.black,
})`
  position: absolute;
  mix-blend-mode: overlay;
  opacity: 0.5;
  top: 200px;
  right: -15%;

  @media (min-width: ${MEDIUM}) {
    top: 340px;
    left: 65%;
  }
`;

const ProductColorSmallSquare = styled(SmallRoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.product.regular,
})`
  position: absolute;
  background: ${({ theme }) => theme.colorsNew.product.light};
  height: 327px;
  width: 327px;
  top: 220px;
  mix-blend-mode: multiply;
  right: -15%;

  @media (min-width: ${MEDIUM}) {
    mix-blend-mode: normal;
    height: 28px;
    width: 28px;
    background: ${({ theme }) => theme.colorsNew.product.regular};
    top: 80px;
    left: 15%;
  }
`;

const UpperBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? `linear-gradient(
    180deg,
    ${theme.colorsNew.gray.black} 5%,
    ${theme.colorsNew.product.dark} 40%,
    ${theme.colorsNew.product.regular} 100%)`
      : theme.colorsNew.product.dark};
`;

const LowerBackground = styled.div`
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  height: 60%;
  background: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? theme.colorsNew.gray.black
      : theme.colorsNew.gray.white};
`;

const baseBackground = variant => theme =>
  variant === 'enterprise'
    ? `linear-gradient(
    180deg,
    ${theme.colorsNew.gray.black} 5%,
    ${theme.colorsNew.product.dark} 25%,
    ${theme.colorsNew.product.regular} 55%)`
    : theme.colorsNew.product.dark;

export function VideoBackground({ children, variant }) {
  return (
    <BackgroundBase
      baseBackground={baseBackground(variant)}
      variant={variant}
      renderBackground={variant => (
        <>
          <UpperBackground variant={variant} />
          <DarkBigSquare variant={variant} />
          <ProductColorSmallSquare />
          <LowerBackground variant={variant} />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
