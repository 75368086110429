import React from 'react';
import { H3 } from '@increasecard/typed-components';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';

import { Container as BaseContainer } from '../components/Container';
import { MEDIUM } from '../breakpoints';
import { useStaticQuery, graphql } from 'gatsby';
import { H1 } from '../components/Typography';
import {
  CountriesTable,
  CountriesTableWrapper,
  CountryAvailabilityCircle,
} from '../components/CountriesTable/CountriesTable';

const Container = styled(BaseContainer)`
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
`;

const Content = styled.div`
  position: relative;
`;

const Title = styled(H1)`
  margin-bottom: 8px;
`;

const ImageWrapper = styled.div`
  display: none;
  width: 372px;
  position: absolute;
  top: 40px;
  right: -24px;
  z-index: 0;
  @media (min-width: ${MEDIUM}) {
    display: block;
  }
`;

const OnlyDesktop = styled.div`
  display: none;

  @media (min-width: ${MEDIUM}) {
    display: block;
  }
`;

const OnlyMobile = styled.div`
  display: block;

  @media (min-width: ${MEDIUM}) {
    display: none;
  }
`;

export function CountriesSection({ title, description, product, image }) {
  const { countries } = useStaticQuery(graphql`
    query {
      strapi {
        countries(sort: "name") {
          name
          codename
          IsoAlpha3
          unicodeFlagChar
        }
      }
    }
  `).strapi;
  const countriesSorted = countries.sort((c1, c2) => {
    if (c1.codename === 'others') return 1;
    if (c2.codename === 'others') return -1;
    return 0;
  });
  return (
    <Container>
      <ImageWrapper>
        <GatsbyImage
          {...(image.childImageSharp || image.imageSharp.childImageSharp)}
        />
      </ImageWrapper>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: title }} weight="normal" />
        <H3 weight="normal">{description}</H3>
        <CountriesTableWrapper>
          <OnlyDesktop>
            <DesktopTable countries={countriesSorted} product={product} />
          </OnlyDesktop>
          <OnlyMobile>
            <MobileTable countries={countriesSorted} product={product} />
          </OnlyMobile>
        </CountriesTableWrapper>
      </Content>
    </Container>
  );
}

function DesktopTable({ countries, product }) {
  return (
    <CountriesTable>
      <thead>
        <tr>
          <th aria-label="País" />
          {countries.map(({ codename, IsoAlpha3, unicodeFlagChar }) => (
            <th key={IsoAlpha3}>
              {codename === 'others'
                ? 'Otros'
                : `${unicodeFlagChar} ${IsoAlpha3}`}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <H3 as="th" style={{ fontWeight: 'bold', textAlign: 'left' }}>
            Increase <span className="productName">{product.name}</span>
          </H3>
          {countries.map(({ codename }) => (
            <td className={product.codename}>
              {product.countries
                .map(({ codename }) => codename)
                .includes(codename) ? (
                <CountryAvailabilityCircle active />
              ) : (
                <CountryAvailabilityCircle />
              )}
            </td>
          ))}
        </tr>
      </tbody>
    </CountriesTable>
  );
}

function MobileTable({ countries, product }) {
  return (
    <CountriesTable>
      <thead>
        <tr>
          <th aria-label="País" />
          <H3 as="th" style={{ textAlign: 'center' }}>
            <strong>
              <span className={product.codename}>{product.name}</span>
            </strong>
          </H3>
        </tr>
      </thead>
      <tbody>
        {countries.map(({ unicodeFlagChar, codename }) => (
          <tr key={codename}>
            <td style={{ fontSize: codename === 'others' ? '16px' : '26px' }}>
              {codename === 'others' ? 'Otros' : unicodeFlagChar}
            </td>
            <td className={product.codename} style={{ textAlign: 'center' }}>
              {product.countries
                .map(({ codename }) => codename)
                .includes(codename) ? (
                <CountryAvailabilityCircle active />
              ) : (
                <CountryAvailabilityCircle />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </CountriesTable>
  );
}
