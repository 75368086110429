import React, { useEffect, useState } from 'react';
import { Container } from '../components/Container';
import { MEDIUM } from '../breakpoints';
import styled from 'styled-components';
import { CountRegisterModal } from '../components/CountRegisterModal';

const CountIFrame = styled.iframe`
  width: 100%;
  height: 1176px;
  margin: 40px 0 20px;
  @media (min-width: ${MEDIUM}) {
    height: 740px;
    margin: 40px 0;
  }
`;

function getSignUpURL() {
  switch (process.env.GATSBY_STAGE) {
    case 'production':
      return 'https://auth.increase.app/sign-up?origin=count';
    case 'staging':
      return 'https://auth.staging.increase.app/sign-up?origin=count';
    case 'development':
    default:
      return 'http://localhost:4444/sign-up?origin=count';
  }
}

const COUNT_URL =
  process.env.GATSBY_STAGE === 'production'
    ? 'https://count.increase.app'
    : 'https://count.staging.increase.app';

export function CountEmbedSection() {
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  useEffect(() => {
    const handleMessage = event => {
      // Prevents handling events from recaptcha and other scripts
      // since 'message' is a universal event
      if (event.origin !== COUNT_URL) return;
      setRegisterModalVisible(true);
    };
    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  return (
    <Container id="countEmbed">
      <CountIFrame src={COUNT_URL} data-hj-allow-iframe=""></CountIFrame>
      <CountRegisterModal
        visible={registerModalVisible}
        onClose={() => setRegisterModalVisible(false)}
        signUpURL={getSignUpURL()}
      />
    </Container>
  );
}
