import React from 'react';
import { Label, H3 } from '@increasecard/typed-components';
import styled from 'styled-components';
import { CTAButton } from '../components/CTAButton';
import { SmartLink } from '../components/SmartLink';
import { Container as BaseContainer } from '../components/Container';
import { Grid } from '../components/Grid';
import { SMALL } from '../breakpoints';
import { H1 } from '../components/Typography';
import { MdiArrowRightIcon } from '@increasecard/icons';

const Container = styled(BaseContainer)`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const ArrowIcon = styled.div.attrs({ children: <MdiArrowRightIcon /> })`
  border-radius: 100%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  background: ${({ theme }) => theme.colorsNew.product.regular};
`;

const Article = styled(SmartLink)`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colorsNew.product.regular};
  padding: 16px 96px 16px 16px;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colorsNew.product.light};
  }

  ${Label} {
    color: ${({ theme }) => theme.colorsNew.product.regular};
    padding-bottom: 8px;
  }
`;

const TextContent = styled.div`
  grid-column: span 2 / span 2;
  @media (min-width: ${SMALL}) {
    grid-column: span 3 / span 3;
  }
`;

const ArticleList = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 2 / span 2;
  @media (min-width: ${SMALL}) {
    grid-column: 5 / span 4;
  }
`;

const ArticleTitle = styled.h3`
  ${({ theme }) => theme.typography.H3};

  @media (min-width: ${SMALL}) {
    ${({ theme }) => theme.typography.H2};
  }
`;

export function HelpSection({ title, supportArticles, description, cta }) {
  return (
    <Container>
      <Grid style={{ gridRowGap: '64px' }}>
        <TextContent>
          <H1 weight="normal" style={{ marginBottom: '16px' }}>
            {title}
          </H1>
          <H3 weight="normal" style={{ marginBottom: '40px' }}>
            {description}
          </H3>
          <CTAButton {...cta} />
        </TextContent>
        <ArticleList>
          {supportArticles.map(a => (
            <Article key={a.id} href={a.url || '#'}>
              <Label>{a.category}</Label>
              <ArticleTitle dangerouslySetInnerHTML={{ __html: a.title }} />

              <ArrowIcon
                style={{
                  position: 'absolute',
                  right: '32px',
                  top: 'calc(50% - 12px)',
                }}
              />
            </Article>
          ))}
        </ArticleList>
      </Grid>
    </Container>
  );
}
