import React from 'react';
import { H3 } from '@increasecard/typed-components';
import styled from 'styled-components';
import { CTAButton } from '../components/CTAButton';
import { Container } from '../components/Container';
import { VideoBackground } from '../backgrounds/VideoBackground';
import { MEDIUM } from '../breakpoints';
import { SuperHeader, H1 } from '../components/Typography';

const ContentWrapper = styled(Container)`
  padding-top: 48px;
  padding-bottom: 64px;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  text-align: center;

  @media (min-width: ${MEDIUM}) {
    padding-top: 96px;
    padding-bottom: 64px;
  }
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: auto;
  border-radius: 20px;
  margin-top: 32px;
  margin-bottom: 48px;
  width: auto;
  height: 215px;

  @media (min-width: ${MEDIUM}) {
    width: 810px;
    height: 508px;
    margin-bottom: 0;
  }
`;

const Subtitle = styled(H3).attrs({ weight: 'normal' })`
  margin: 0 auto;
  width: 20em;

  @media (min-width: ${MEDIUM}) {
    width: 30em;
  }
`;

const CTAGroup = styled.div`
  display: grid;
  grid-column-gap: 16px;
  align-items: center;
  margin: 0 auto;

  ${H1} {
    margin-bottom: 16px;
  }

  @media (min-width: ${MEDIUM}) {
    justify-content: center;
    margin: 64px auto 0;
    grid-template-columns: repeat(2, max-content);

    ${H1} {
      margin-bottom: 0;
      width: max-content;
    }
  }
`;

export function VideoSection({
  title,
  subtitle,
  videoURL,
  product,
  cta,
  variant,
  videoCTAText,
}) {
  return (
    <VideoBackground variant={variant}>
      <ContentWrapper>
        <div>
          <SuperHeader
            style={{ marginBottom: '16px' }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
          {variant === 'enterprise' && (
            <CTAButton {...cta} style={{ marginTop: '32px' }} />
          )}
        </div>
        <VideoFrame
          title={`video ${product.name}`}
          src={videoURL}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope"
        ></VideoFrame>
        {variant !== 'enterprise' && (
          <CTAGroup>
            {videoCTAText && (
              <H1
                dangerouslySetInnerHTML={{ __html: videoCTAText }}
                style={{ color: '#182026' }}
              />
            )}
            <CTAButton {...cta} />
          </CTAGroup>
        )}
      </ContentWrapper>
    </VideoBackground>
  );
}
