import React from 'react';
import styled from 'styled-components';
import { Container } from '../components/Container';
import { Grid } from '../components/Grid';
import { MEDIUM, LARGE } from '../breakpoints';
import { Metric } from '../components/Metric';

const MetricsWrapper = styled(Grid)`
  grid-gap: 16px;
  padding: 64px 0 24px;

  @media (min-width: ${MEDIUM}) {
    grid-gap: 24px;
    grid-template-columns: repeat(8, 95px);
    padding: 24px 0 24px;
  }
  @media (min-width: ${LARGE}) {
    grid-template-columns: repeat(8, 115px);
  }
`;

const Wrapper = styled.div`
  background: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? theme.colorsNew.gray.black
      : theme.colorsNew.product.dark};
`;

export function MetricsSection({ metrics, variant }) {
  return (
    <Wrapper variant={variant}>
      <Container>
        <MetricsWrapper>
          {metrics.map(({ desktopColumns, id, ...rest }) => (
            <Metric
              className={`grid-span-md-${desktopColumns} grid-span-sm-2`}
              {...rest}
              key={id}
              variant={variant}
            />
          ))}
        </MetricsWrapper>
      </Container>
    </Wrapper>
  );
}
