import React from 'react';
import { Container as BaseContainer } from '../components/Container';
import { Paragraph } from '@increasecard/typed-components';
import styled from 'styled-components';
import { SMALL } from '../breakpoints';
import { H1 } from '../components/Typography';

const Container = styled(BaseContainer)`
  padding: 104px 64px 80px;
`;

const Title = styled(H1)`
  color: ${({ theme }) => theme.colorsNew.product.accent};
  margin-bottom: 32px;
`;

const BenefitsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Benefit = styled(Paragraph)`
  padding: 16px 0;
  flex-basis: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colorsNew.product.accent};

  :last-child {
    border-bottom: 0;
  }

  @media (min-width: ${SMALL}) {
    :last-child,
    :nth-last-child(2) {
      border-bottom: 0;
    }
    flex-basis: calc(50% - 64px);
  }
`;

export function EnterpriseBenefitsSection({
  variant,
  title,
  enterpriseBenefits,
}) {
  return (
    <Container>
      <Title variant={variant}>{title}</Title>
      <BenefitsWrapper>
        {enterpriseBenefits.map(({ id, text }) => (
          <Benefit dangerouslySetInnerHTML={{ __html: text }} key={id} />
        ))}
      </BenefitsWrapper>
    </Container>
  );
}
