import React from 'react';
import { CTAButton } from '../components/CTAButton';
import { Container as BaseContainer } from '../components/Container';
import { H3 } from '@increasecard/typed-components';
import GatsbyImage from 'gatsby-image';
import { Grid as BaseGrid } from '../components/Grid';
import styled from 'styled-components';
import { SMALL, MEDIUM } from '../breakpoints';
import { SuperHeader } from '../components/Typography';

const Container = styled(BaseContainer)`
  padding-top: 40px;
  position: relative;
  bottom: -80px;
  z-index: 1;

  @media (min-width: ${SMALL}) and (max-width: ${MEDIUM}) {
    bottom: 0;
  }

  @media (min-width: ${SMALL}) {
    padding-bottom: 80px;
  }
`;

const Grid = styled(BaseGrid)`
  align-items: center;
  grid-auto-flow: row dense;
`;

const ImageWrapper = styled.div`
  margin-top: 24px;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 2 / span 2;
  text-align: center;

  @media (min-width: ${SMALL}) {
    text-align: left;
    grid-column: 6 / span 3;

    ${CTAButton} {
      width: max-content;
      margin-right: auto;
    }
  }
`;

export function IntegrationsSection({ title, description, image, cta }) {
  return (
    <Container>
      <Grid>
        <ContentWrapper>
          <SuperHeader style={{ marginBottom: '24px' }}>{title}</SuperHeader>
          <H3 weight="normal" style={{ marginBottom: '24px' }}>
            {description}
          </H3>
          <CTAButton {...cta} />
        </ContentWrapper>
        <ImageWrapper className="grid-span-md-4 grid-span-sm-2">
          <GatsbyImage
            {...(image.childImageSharp || image.imageSharp.childImageSharp)}
          />
        </ImageWrapper>
      </Grid>
    </Container>
  );
}
