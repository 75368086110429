import React from 'react';
import { CTAButton } from '../components/CTAButton';
import { Container as BaseContainer } from '../components/Container';
import { H3 } from '@increasecard/typed-components';
import GatsbyImage from 'gatsby-image';
import { Grid as BaseGrid } from '../components/Grid';
import styled from 'styled-components';
import { SMALL, MEDIUM } from '../breakpoints';
import { SuperHeader } from '../components/Typography';

const Container = styled(BaseContainer)`
  padding-top: 40px;
  padding-bottom: 80px;
  position: relative;
  bottom: -40px;
  z-index: 1;

  @media (min-width: ${SMALL}) {
    padding-bottom: 40px;
  }
`;

const Grid = styled(BaseGrid)`
  align-items: center;
  grid-auto-flow: row dense;
`;

const ImageWrapper = styled.div`
  grid-column: span 2 / span 2;
  @media (min-width: ${SMALL}) {
    grid-column: 5 / span 4;
  }
  @media (min-width: ${MEDIUM}) {
    grid-column: span 5 / span 5;
  }
  margin-top: 32px;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${CTAButton} {
    width: max-content;
  }
`;

export function ComplementarySolutionsSection({
  title,
  description,
  image,
  cta,
}) {
  return (
    <Container>
      <Grid>
        <ContentWrapper className="grid-span-md-3 grid-span-sm-2">
          <SuperHeader style={{ marginBottom: '24px' }}>{title}</SuperHeader>
          <H3 weight="normal" style={{ marginBottom: '24px' }}>
            {description}
          </H3>
          <CTAButton {...cta} />
        </ContentWrapper>
        <ImageWrapper>
          <GatsbyImage
            {...(image.childImageSharp || image.imageSharp.childImageSharp)}
          />
        </ImageWrapper>
      </Grid>
    </Container>
  );
}
