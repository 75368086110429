import React from 'react';
import { IndustriesBackground } from '../backgrounds/IndustriesBackground';
import { Container as BaseContainer } from '../components/Container';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { SuperHeader } from '../components/Typography';

const Container = styled(BaseContainer)`
  text-align: center;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  padding-top: 72px;
  padding-bottom: 72px;
`;

const IndustriesList = styled.ul`
  display: flex;
  width: 100%;
  justify-content: space-between;
  justify-content: center;
  flex-wrap: wrap;

  > * {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
    margin-bottom: 16px;
  }

  @media (min-width: ${MEDIUM}) {
    width: 680px;
    margin: 0 auto;
    > * {
      margin-bottom: 32px;
      flex-basis: unset;
      max-width: unset;
    }
  }
`;
const IndustriesListItem = styled.li`
  ${({ theme }) => theme.typography.H3}
  color: ${({ theme }) => theme.colorsNew.product.dark};
  background: ${({ theme }) => theme.colorsNew.gray.white};
  height: 35px;
  border-radius: 30px;
  padding: 0 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
`;

export function IndustriesSection({ title, industries }) {
  return (
    <IndustriesBackground baseColor={theme => theme.colorsNew.pay.dark}>
      <Container>
        <SuperHeader
          dangerouslySetInnerHTML={{ __html: title }}
          style={{
            marginBottom: '48px',
            maxWidth: '12em',
            margin: '0 auto 48px',
          }}
        />
        <IndustriesList>
          {industries &&
            industries.map(({ name }) => (
              <IndustriesListItem key={name}>{name}</IndustriesListItem>
            ))}
        </IndustriesList>
      </Container>
    </IndustriesBackground>
  );
}
