import React from 'react';
import { Container as BaseContainer } from '../components/Container';
import styled from 'styled-components';
import { Paragraph } from '@increasecard/typed-components';
import {
  VisaPaymentMethodIcon,
  AmexPaymentMethodIcon,
  CabalPaymentMethodIcon,
  MastercardPaymentMethodIcon,
  BankPaymentMethodIcon,
  NaranjaPaymentMethodIcon,
  MaestroPaymentMethodIcon,
  NativaPaymentMethodIcon,
  DinersPaymentMethodIcon,
  DiscoverPaymentMethodIcon,
  CencosudPaymentMethodIcon,
  ArgencardPaymentMethodIcon,
  MercadoPago2PaymentMethodIcon,
  SodexoPaymentMethodIcon,
  JcbPaymentMethodIcon,
  LiderPaymentMethodIcon,
  OcaPaymentMethodIcon,
  CreditelPaymentMethodIcon,
  CabalDebitoPaymentMethodIcon,
  Ahora18PaymentMethodIcon,
  Ahora12PaymentMethodIcon,
  Ahora3PaymentMethodIcon,
  Ahora6PaymentMethodIcon,
  AmericanExpressPaymentMethodIcon,
  CashPaymentMethodIcon,
  MercadoPagoPaymentMethodIcon,
  VisaDebitoPaymentMethodIcon,
  TarjetaShoppingPaymentMethodIcon,
  CordobesaPaymentMethodIcon,
  RapipagoPaymentMethodIcon,
  PagoFacilPaymentMethodIcon,
  LinkPaymentMethodIcon,
  WalmartPaymentMethodIcon,
  ProvinciaNetPaymentMethodIcon,
  CobroExpressPaymentMethodIcon,
  PagoEfectivoPaymentMethodIcon,
  HiperCardPaymentMethodIcon,
  EloPaymentMethodIcon,
  CmrFalabellaPaymentMethodIcon,
} from '@increasecard/icons';
import { Grid } from '../components/Grid';
import { PaymentMethodsBackground } from '../backgrounds/PaymentMethodsBackground';
import { SMALL } from '../breakpoints';
import { H1 } from '../components/Typography';

const ICONS_BY_PAYMENT_METHOD = {
  visa: VisaPaymentMethodIcon,
  cabalDebito: CabalDebitoPaymentMethodIcon,
  ahora18: Ahora18PaymentMethodIcon,
  ahora12: Ahora12PaymentMethodIcon,
  ahora3: Ahora3PaymentMethodIcon,
  ahora6: Ahora6PaymentMethodIcon,
  americanExpress: AmericanExpressPaymentMethodIcon,
  amex: AmexPaymentMethodIcon,
  argencard: ArgencardPaymentMethodIcon,
  bankPayment: BankPaymentMethodIcon,
  cmrFalabella: CmrFalabellaPaymentMethodIcon,
  cabal: CabalPaymentMethodIcon,
  cash: CashPaymentMethodIcon,
  cencosud: CencosudPaymentMethodIcon,
  cordobesa: CordobesaPaymentMethodIcon,
  creditel: CreditelPaymentMethodIcon,
  diners: DinersPaymentMethodIcon,
  discover: DiscoverPaymentMethodIcon,
  jcb: JcbPaymentMethodIcon,
  lider: LiderPaymentMethodIcon,
  maestro: MaestroPaymentMethodIcon,
  mastercard: MastercardPaymentMethodIcon,
  mercadoPago: MercadoPagoPaymentMethodIcon,
  mercadoPago2: MercadoPago2PaymentMethodIcon,
  naranja: NaranjaPaymentMethodIcon,
  nativa: NativaPaymentMethodIcon,
  oca: OcaPaymentMethodIcon,
  sodexo: SodexoPaymentMethodIcon,
  tarjetaShopping: TarjetaShoppingPaymentMethodIcon,
  visaDebito: VisaDebitoPaymentMethodIcon,
  rapipago: RapipagoPaymentMethodIcon,
  pagoFacil: PagoFacilPaymentMethodIcon,
  link: LinkPaymentMethodIcon,
  cbu: BankPaymentMethodIcon,
  walmart: WalmartPaymentMethodIcon,
  provinciaNet: ProvinciaNetPaymentMethodIcon,
  cobroExpress: CobroExpressPaymentMethodIcon,
  pagoEfectivo: PagoEfectivoPaymentMethodIcon,
  hiperCard: HiperCardPaymentMethodIcon,
  elo: EloPaymentMethodIcon,
};

const Icon = styled.div`
  height: 42px;
  width: 42px;
`;

function PaymentMethodIcon({ codename }) {
  const Component = ICONS_BY_PAYMENT_METHOD[codename];

  if (Component === undefined) return <>{codename}</>;
  return <Icon as={Component} />;
}

const Container = styled(BaseContainer)`
  padding-top: 64px;
  padding-bottom: 64px;

  @media (min-width: ${SMALL}) {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  text-align: center;
`;

const PaymentMethodsList = styled(Grid).attrs({ as: 'ul' })`
  margin: 32px 0;
  grid-row-gap: 16px;
`;
const PaymentMethodsListItem = styled.li`
  background: ${({ theme }) => theme.colorsNew.gray.white};
  border-radius: 9px;
  box-shadow: ${({ theme }) => theme.shadows.elevate};
  display: inline-flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  flex-direction: column;
  grid-column: span 2 / span 2;

  @media (min-width: ${SMALL}) {
    height: 100%;
    flex-direction: row;
    grid-column-start: ${({ columnStart }) => columnStart};
    grid-column-end: ${({ columnEnd }) => columnEnd};
  }
`;

const PaymentMethodsCountry = styled.div`
  ${({ theme }) => theme.typography.label}
  font-weight: 500;
  color: ${({ theme }) => theme.colorsNew.product.dark};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colorsNew.gray.grayLight};
  height: 40px;
  width: 100%;
  border-radius: 9px 9px 0 0;

  @media (min-width: ${SMALL}) {
    height: 100%;
    padding: 36px 0;
    width: 128px;
    border-radius: 9px 0 0 9px;
    border-bottom-left-radius: 9px;
  }
`;

const PaymentMethodsIcons = styled.div`
  background: ${({ theme }) => theme.colorsNew.gray.white};
  border-radius: 9px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 42px);
  align-items: center;
  align-content: center;
  justify-content: start;
  grid-column-gap: 16px;
  width: 100%;
  padding: 0 24px;
  border-radius: 0 0 9px 9px;

  @media (min-width: ${SMALL}) {
    border-radius: 0 9px 9px 0;
    padding: 8px 24px;
    width: calc(100% - 128px);
  }
`;

const TextWrapper = styled.div`
  margin: 0 auto;
  padding: 8px 0;
  background: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? theme.colorsNew.gray.black
      : theme.colorsNew.gray.white};

  @media (min-width: ${SMALL}) {
    width: max-content;
  }
`;

export function PaymentMethodsSection({
  title,
  description,
  paymentMethodsByCountry,
  variant,
}) {
  return (
    <PaymentMethodsBackground variant={variant}>
      <Container>
        <TextWrapper variant={variant}>
          <H1
            dangerouslySetInnerHTML={{ __html: title }}
            weight="normal"
            style={{ margin: '0 auto 16px', maxWidth: '20em' }}
          />
          <Paragraph dangerouslySetInnerHTML={{ __html: description }} />
        </TextWrapper>
        <PaymentMethodsList>
          {paymentMethodsByCountry.map(
            ({ country, columnEnd, columnStart, methods }) => (
              <PaymentMethodsListItem
                columnStart={columnStart}
                columnEnd={columnEnd + 1}
                key={country.name}
              >
                <PaymentMethodsCountry>{country.name}</PaymentMethodsCountry>
                <PaymentMethodsIcons>
                  {methods.map(({ codename }) => (
                    <PaymentMethodIcon key={codename} codename={codename} />
                  ))}
                </PaymentMethodsIcons>
              </PaymentMethodsListItem>
            )
          )}
        </PaymentMethodsList>
      </Container>
    </PaymentMethodsBackground>
  );
}
