import { ArrowRightIcon } from '@increasecard/icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BackgroundBase } from '../backgrounds/BackgroundBase';
import { RoundedSquare } from '../backgrounds/shapes/RoundedSquare';
import { MEDIUM } from '../breakpoints';
import { Container as BaseContainer } from '../components/Container';
import { DisplayTitle } from '../components/DisplayTitle';
import { PricingOption } from './PricingOption';

const SmallSquare = styled(RoundedSquare).attrs({
  size: '72px',
  bgColor: theme => theme.colorsNew.product.accent,
  rotation: '30deg',
})`
  position: absolute;
  bottom: calc(264px - 72px / 2);
  right: 208px;
`;

const TinySquare = styled(RoundedSquare).attrs({
  size: '25px',
  bgColor: theme => theme.colorsNew.product.accent,
  rotation: '30deg',
})`
  position: absolute;
  top: 64px;
  left: 10%;
  @media (min-width: ${MEDIUM}) {
    top: 160px;
  }
`;

const WhiteRectangle = styled.div`
  height: 124px;
  background: ${({ theme }) => theme.colorsNew.gray.white};
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const Container = styled(BaseContainer)`
  padding-top: 40px;
  text-align: center;
`;

const Title = styled(DisplayTitle)`
  margin-bottom: 16px;
`;

const PricingOptionsWrapper = styled.div`
  @media (max-width: ${MEDIUM}) {
    width: 100vw;
    position: relative;
    left: -16px;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;
      height: 100%;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0)
      );
    }
  }
`;

const PricingOptions = styled.ul`
  display: flex;
  gap: 32px;
  justify-content: center;
  margin-top: 24px;
  padding: 32px 16px 64px;
  overflow: auto;
  flex-wrap: wrap;
  li {
    width: 270px;
  }

  @media (min-width: ${MEDIUM}) {
    padding: 0 16px 40px 16px;
    overflow: visible;
  }
`;

const CountryPricingText = styled.h3`
  ${({ theme }) => theme.typography.body}
  font-weight: bold;
  max-width: 18em;
  margin: 0 auto;

  @media (min-width: ${MEDIUM}) {
    ${({ theme }) => theme.typography.H2}
    max-width: 28em;
  }
`;

const CountrySelect = styled.select`
  font-size: 24px;
  line-height: 48px;
  text-decoration: underline;
  padding-right: 38px;

  optgroup,
  option {
    font-size: 16px;
  }
`;

const CountrySelectWrapper = styled.div`
  margin: 32px 0;
  display: inline-block;
  position: relative;
`;

export function PricingSection({ title, countryPricing, product }) {
  const [country, setCountry] = useState('argentina');
  const currentCountryPricing = countryPricing.find(
    singleCountryPricing => singleCountryPricing.country.codename === country
  );
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.product.light}
      renderBackground={() => (
        <>
          <SmallSquare />
          <TinySquare />
          <WhiteRectangle />
        </>
      )}
    >
      <Container>
        <Title dangerouslySetInnerHTML={{ __html: title }} />

        <CountrySelectWrapper>
          <CountrySelect
            onChange={e => setCountry(e.target.value)}
            value={country}
          >
            {countryPricing.map(({ country }) => (
              <option value={country.codename} key={country.codename}>
                {country.name}
              </option>
            ))}
          </CountrySelect>
          <ArrowRightIcon
            width={40}
            height={40}
            style={{
              transform: 'rotate(90deg)',
              position: 'absolute',
              right: 0,
              top: '6px',
              pointerEvents: 'none',
            }}
          />
        </CountrySelectWrapper>
        <CountryPricingText>
          {currentCountryPricing.pricingText.title}
        </CountryPricingText>
        <PricingOptionsWrapper>
          <PricingOptions>
            {currentCountryPricing.pricingTierBox.map(({ id, ...rest }) => (
              <li key={id}>
                <PricingOption {...rest} />
              </li>
            ))}
          </PricingOptions>
        </PricingOptionsWrapper>
      </Container>
    </BackgroundBase>
  );
}
