import React from 'react';
import {
  Modal,
  ModalFooter,
  Button,
  ModalContent,
  H2,
  Paragraph,
} from '@increasecard/typed-components';
import headingImage from '../assets/images/countRegisterModalHeadingImage.svg';

export function CountRegisterModal({ visible, signUpURL, onClose }) {
  return (
    <Modal onClose={onClose} visible={visible} width={400}>
      <img alt="Count" src={headingImage} />
      <ModalContent>
        <H2>Crea tu cuenta hoy</H2>
        <br />
        <Paragraph>
          Accede gratis a nuestra calculadora de cuotas y conoce nuestro
          marketplace con beneficios exclusivos para usuarios.
        </Paragraph>
      </ModalContent>
      <ModalFooter align="center">
        <Button as="a" href={signUpURL}>
          Registrarme gratis
        </Button>
      </ModalFooter>
    </Modal>
  );
}
